import {
  typography,
  size,
  colors,
  mediaQueries,
  Container as RawContainer,
  Col,
} from '@everlywell/leaves';
import styled from 'styled-components';

import { SectionTitle as RawSectionTitle } from '../../Enterprise/SectionTitle';

const Container = styled(RawContainer)`
  padding-top: ${size.xl4}px;
  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: ${size.xl7}px;
  }
`;

const SectionTitle = styled(RawSectionTitle)`
  text-align: center;
  margin-bottom: ${size.sm}px;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.sm}px;
  }
`;

const SectionHeadline = styled.h2`
  ${typography.displayText}
  margin-bottom: ${size.xl2}px;
  text-align: center;
  color: ${colors.gray4};
  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: ${size.xl5}px;
  }
`;

const LabelCopy = styled.span`
  ${typography.bodyTextSmall}
  text-align: center;
`;

const PercentageBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${size.xs1}px;
  margin-bottom: ${size.xl3}px;
  width: 50%;
  ${mediaQueries.forTabletHorizontalUp} {
    width: 166px;
  }
`;

const PercentageGroup = styled(Col)`
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const Percentage = styled.span`
  font-size: 46.2px;
  line-height: 56px;
  color: ${colors.teal5};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${mediaQueries.forTabletHorizontalUp} {
    font-size: 58.5px;
    line-height: 72px;
  }
`;

const Mask = styled.div`
  box-shadow: inset 0 0 5px 18px ${colors.white};
  width: 120%;
  height: 120%;
  position: absolute;
  z-index: 99;
  ${mediaQueries.forTabletHorizontalUp} {
    box-shadow: inset 0 0 12px 18px ${colors.white};
  }
`;

export {
  Container,
  SectionTitle,
  SectionHeadline,
  Percentage,
  LabelCopy,
  PercentageBlock,
  PercentageGroup,
  Mask,
};
