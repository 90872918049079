import React from 'react';
import ByTheNumbersSection from 'components/Homepage/ByTheNumbersSection';
import { PercentageWithLabel } from '../../../utils/types';

export interface ByTheNumbersContainerProps {
  content: {
    title: string;
    headline: string;
    listOfContentWithImages: PercentageWithLabel[];
  };
}

export const ByTheNumbersContainer: React.FC<ByTheNumbersContainerProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }
  const { title, headline, listOfContentWithImages } = content;

  const formattedListOfPercentages = listOfContentWithImages.map(item => {
    return {
      number: item.number,
      heading: item.heading,
    };
  });

  return (
    <ByTheNumbersSection
      title={title}
      headline={headline}
      listOfContent={formattedListOfPercentages}
    />
  );
};

export default ByTheNumbersContainer;
