import { Row, Col } from '@everlywell/leaves';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { PercentageWithLabel } from '../../../utils/types';
import * as S from './styles';

import 'odometer/themes/odometer-theme-minimal.css';

//Stubbing out for serverside rendering
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let Odometer: any;
try {
  Odometer = require('react-odometerjs').default;
} catch (e) {
  Odometer = undefined;
}

export interface ByTheNumbersSectionProps {
  title: string;
  headline: string;
  listOfContent: PercentageWithLabel[];
}

export const ByTheNumbersSection: React.FC<ByTheNumbersSectionProps> = ({
  title,
  headline,
  listOfContent,
}) => {
  const [percentages, setPercentages] = useState([0, 0, 0, 0]);

  const [sectionInView] = useInView({
    triggerOnce: true,
    onChange: (inView: boolean) => {
      if (inView) {
        setPercentages(listOfContent.map((x) => x.number));
      }
    },
  });

  return (
    <S.Container data-testid="bytheNumbersSection">
      <Row center="xs">
        <Col xs={12} lg={10}>
          <S.SectionTitle text={title} />
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={12} lg={10}>
          <S.SectionHeadline>{headline}</S.SectionHeadline>
        </Col>
      </Row>
      <Row center="xs">
        <S.PercentageGroup xs={12} lg={10}>
          {listOfContent.map((content, index) => (
            <S.PercentageBlock
              ref={sectionInView}
              key={`by-the-numbers-${index}`}
            >
              <S.Percentage>
                <S.Mask />
                {typeof Odometer !== 'undefined' && (
                  <>
                    <Odometer duration={1000} value={percentages[index]} />%
                  </>
                )}
              </S.Percentage>
              <S.LabelCopy>{content.heading}</S.LabelCopy>
            </S.PercentageBlock>
          ))}
        </S.PercentageGroup>
      </Row>
    </S.Container>
  );
};

export default ByTheNumbersSection;
